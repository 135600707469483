<template>
  <div class="w-100 my-5">
    <div class="my-5">
      <span class="font-15 font-bold">{{ `${checkedList.length} / ${transactionTempList.length}` }}</span>
      <v-btn class="mx-5 button-normal" small @click="selectAll(false)">
        <span>Select All</span>
      </v-btn>
      <v-btn class="mx-5 button-normal" small @click="selectAll(true)">
        <span>Select a week before</span>
      </v-btn>
      <v-btn class="mx-5 button-red" small @click="deleteTransactionTempInfoTask()">
        <span>Delete selected</span>
      </v-btn>
    </div>
    <div class="row my-5" v-if="(transactionTempList.length > 0)">
      <div class="col-sm-12" v-for="(item, index) in transactionTempList" :key="(item, index)">
        <v-card class="my-2 box-white d-flex flex-column">
          <div class="m-2 row">
            <input :id="item.referenceId" :value="item" type="checkbox" checked v-model="checkedList" />
            <span class="col-sm-3">{{ item.referenceId }}</span>
            <span class="col-sm-2">{{ getUserName(item.buyerId) }}</span>
            <span :class="`col-sm ${getPayPalWebHookColor(item.referenceId)}`">{{ getPayPalWebHookId(item.referenceId) }}</span>
            <span class="col-sm">{{ getPriceFormat(item.totalPrice) }}</span>
            <span class="col-sm font-bold">{{ item.isDrink ? 'Drink' : '' }}</span>
            <span class="col-sm">{{ getDateStringFromTimestamp(item.createdAt) }}</span>
            <v-btn class="ml-auto mr-10" icon small @click="deleteTransactionTempInfoTask(item.referenceId, item.buyerId)">
              <i class="flaticon-delete color-red"></i>
            </v-btn>
          </div>
        </v-card>
      </div>
    </div>
    <div class="py-40 center" v-else>
      <span class="font-20 alpha-07">{{ $t('no_data_found') }}</span>
    </div>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../main';
import { getPriceFormat, getDateStringFromTimestamp, showLoading } from '../../../functions';

export default {
  name: 'TransactionTemp',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    userList() {
      return this.$store.state.userList || [];
    },
    paypalWebHookList() {
      return this.$store.state.paypalWebHookList;
    },
    transactionTempList() {
      return this.$store.state.transactionTempList.filter(element => !element.referenceId.startsWith(this.PAYMENT_TYPE_VIP));
    }
  },
  data() {
    return {
      checkedList: []
    }
  },
  mounted() {
    if (window.location.hostname !== 'localhost') {
      this.$router.push('/');
    }
  },
  methods: {
    getPriceFormat,
    getDateStringFromTimestamp,
    getUserName(buyerId) {
      const info = this.userList.find(element => element.userId === buyerId);
      return info ? info.userName : '--';
    },
    getPayPalWebHookId(referenceId) {
      if (referenceId.startsWith('pi_')) {
        return 'Stripe';
      } else {
        const info = this.paypalWebHookList.find(element => element.resource && element.resource.purchase_units && element.resource.purchase_units.length > 0 && element.resource.purchase_units[0] && element.resource.purchase_units[0].reference_id === referenceId);
        return info ? info.id : 'No WebHook';
      }
    },
    getPayPalWebHookColor(referenceId) {
      if (referenceId.startsWith('pi_')) {
        return 'font-bold color-blue';
      } else {
        const info = this.paypalWebHookList.find(element => element.resource && element.resource.purchase_units && element.resource.purchase_units.length > 0 && element.resource.purchase_units[0] && element.resource.purchase_units[0].reference_id === referenceId);
        return info ? 'font-bold color-red' : '';
      }
    },
    selectAll(aWeekBefore) {
      if (this.checkedList.length > 0) {
        this.checkedList = [];
      } else {
        const curSeconds = Math.round(Date.now() / 1000);
        this.transactionTempList.forEach(element => {
          if (!aWeekBefore || (curSeconds - element.createdAt.seconds) > 86400 * 7) {
            this.checkedList.push(element)
          }
        });
      }
    },
    deleteTransactionTempInfoTask(referenceId = '', buyerId = '') {
      const params = {
        functionName: 'deleteTransactionTempInfo',
      };
      if (referenceId) {
        params['referenceId'] = referenceId;
        params['buyerId'] = buyerId;
      } else {
        if (this.checkedList.length > 0) {
          const selectedList = [];
          this.checkedList.forEach(info => {
            selectedList.push({
              referenceId: info.referenceId,
              buyerId: info.buyerId
            })
          });
          params['selectedList'] = selectedList;
        } else {
          this.$toast.error('Please select something.');
          return;
        }
      }
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>